import React from 'react';
import { Button } from '@material-ui/core';
import styles from './styles.module.css';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import PrintIcon from '@mui/icons-material/Print';

function ItemSummaryReport() {
  return (
    <div className={styles.contentWrapper}>
      {/* <Loader isOpen={loadingIndicator} /> */}

      <div className={styles.titleSec}>
        <span className={styles.title}>
          {' '}
          Reports<span className={styles.menuTitle}>Generation</span>
        </span>
        <div style={{ justifyContent: 'flex-end', marginRight: '10px' }}>
          <div style={{ paddingBottom: '4px' }}>
            <label className={styles.label}>Print Report</label>
          </div>
          <Button
            variant="contained"
            color="primary"
            className={styles.actionBtn}
            style={{ backgroundColor: '#00a65a' }}
            onClick={window.print}
          >
            <PrintIcon className={styles.actionBtnIcon} />
            Print
          </Button>
        </div>
      </div>
    </div>
  );
}

export default withConsoleBase(ItemSummaryReport);
