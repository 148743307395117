import SanoftLogo192 from './images/sanoft_logo_192.png';
import SanoftLogo512 from './images/sanoft_logo_512.png';

export const BACKEND_URL = process.env.REACT_APP_API_BASE_URL || 'https://api.rentals.sanoft.com/api';

export const STORAGE_KEYS = {
  REFRESH_TOKEN: 'sr-refresh-token',
  ACCESS_TOKEN: 'sr-access-token',
  USER_ID: 'sr-auth-user-id',
  SHOP_ID: 'sr-selected-shop-id',
  FIRST_NAME: 'sr-first-name',
  LAST_NAME: 'sr-last-name',
  USERNAME: 'sr-username',
  SHOP_NAME: 'sr-shop-name',
  ACTIVE_TAB: 'sr-active-tab',
};

export const DEFAULT_ERROR_MESSAGE = 'Oops, Something went wrong. If this persists, please contact us.';

export const HEADERS = {
  AUTH: 'Authorization',
  SHOP_ID: 'shop-id',
};

export const GST_PREFERENCES = {
  0: 'No Tax',
  1: 'Exclude Tax',
  2: 'Include Tax',
};

export const USER_PERMISSIONS = {
  DENY: 'deny',
  READ: 'read',
  WRITE: 'write',
};

export const SUBSCRIPTION_TYPE = {
  BASIC: 'basic',
  STANDARD: 'standard',
  PREMIUM: 'premium',
};

export const ROUTES = {
  LOGIN: '/login',
  RESET_PASSWORD: '/reset-password',
  SIGNUP: '/signup',
  ANY: '/',
  CONSOLE: '/console',

  DASHBOARD: '/console/dashboard',
  EDIT_SALE: '/console/dashboard/:saleId',
  ACTIVE_ORDERS: '/console/active-orders',
  ITEMS: '/console/items',
  CATEGORIES: '/console/categories',
  CUSTOMERS: '/console/customers',
  CUSTOMER_DETAILS: '/console/customers/:customerId',
  ITEM_SUMMARY: '/console/report/item-summary',
  PROFIT_ITEM_SUMMARY: '/console/report/profit/item-summary',
  ITEMISED_BILL: '/console/report/sale-items',
  PROFIT_ITEMISED_BILL: '/console/report/profit/sale-items',
  BILL_SUMMARY: '/console/report/sale-summary',
  PROFIT_BILL_SUMMARY: '/console/report/profit/sale-summary',
  PAYMENT_MODE_REPORT: '/console/report/payment-mode',
  SETTINGS: '/console/settings',
  SETTINGS_TAB: '/console/settings/:tab',
  SETTINGS_SUB_TAB: '/console/settings/:tab/:subTab',
  PERMISSION_DETAIL: '/settings/shop-users/:id',
  PAGE_404: '/page-404',
};

export const CONFIGS = {
  MAX_IMAGE_SIZE: 101000,
  LOGO_MAX_SIZE: 202000,
};

export const CONTACT_NUMBER = '919746693296';

export const PRINTER_TYPES = ['USB', 'Network', 'Sunmi', 'Bluetooth', 'PDF', 'Whatsapp'];

export const INVOICE_FORMATS = [
  '2 Inch',
  '2 Inch Sunmi',
  '2 Inch Image - English',
  '2 Inch image - Multi language',
  '3 Inch',
  '3 Inch Image - English',
  '3 Inch Image - Multi languate',
];

export const KOT_FORMATS = [
  '2 Inch',
  '2 Inch Sunmi',
  '2 Inch Invoice',
  '3 Inch',
  '3 Inch Invoice',
  '3 Inch Image Invoice',
  '3 Inch Image - English',
];

export const BARCODE_PRINTER_TYPES = ['USB', 'Network'];
export const BARCODE_PRINTER_FORMATS = ['38 X 25', '50 X 25'];

export const SUFFIX_OPTIONS = ['FID', 'SID'];

export const TAX_PREFERENCES = [
  { 'No Tax': 'No Tax' },
  { 'Excluding Tax': 'Excluding Tax' },
  { 'Including Tax': 'Including Tax' },
];

export const TAX_REGIONS = [{ India: 'India' }, { 'Saudi Arabia': 'Saudi' }, { UAE: 'UAE' }, { Oman: 'Oman' }];

export const THEME_CONFIG = {
  default: {
    name: 'sanoft',
    color: {
      primary: '#07396d',
      secondary: '#03204c',
      tertiary: '#72c7ec',
      header: '#3f8dce',
      sub_header: '#03204c',
      bg_color: '#ececec',
      active: '#03204c',
      active_bg: '#065C96',
    },
    variable: {
      name: 'SANOFT',
      title:
        'Sanoft POS - Secure and Reliable Point of Sale System. Experience trustworthy and secure transactions with Sanoft POS. Perfect for retail, restaurants, and more. Start your free trial today!',
      logo512: SanoftLogo512,
      logo192: SanoftLogo192,
      phone: '919567453182',
      trn_label: 'GSTIN',
    },
  },
};

export const COUNTRY_CONFIGRATION = {
  IN: { name: 'India', currency: '₹', code: 'IN' },
  SA: { name: 'Saudi Arabia', currency: 'SAR', code: 'SA' },
  OM: { name: 'Oman', currency: 'OMR', code: 'OM' },
  AE: { name: 'UAE', currency: 'AED', code: 'AE' },
  US: { name: 'US', currency: '$', code: 'US' },
};

export const REGISTER_STEPS = ['signup', 'shop type', 'counter type', 'basic setup'];

const DEFAULT_SHOP_SETTINGS = {
  advanced_quantity_control: false,
  amount_spent_for_loyalty_point: 100,
  amount_with_three_fraction: false,
  barcode_printer_format: '38 X 25',
  barcode_printer_note: null,
  barcode_printer_type: 'USB',
  consolidated_kot_format: '3 Inch',
  consolidated_kot_printer_ip: null,
  counter_foil_with_invoice: false,
  currency_fraction_name: null,
  currency_name: null,
  enable_table_selection_pos: false,
  invoice_format: '3 Inch Image',
  invoice_no_next: 1,
  invoice_no_prefix: '2024/T1/',
  kitchen_kot_format: '3 Inch',
  kot_with_invoice_printer_ip: null,
  loyalty_point_conversion_rate: 1,
  order_types: null,
  order_with_full_payment_only: true,
  price_with_tax_in_invoice: true,
  print_invoice_on_payment_update: false,
  print_total_save_on_invoice: false,
  printer_type: 'USB',
  sales_sync_platform: null,
  sales_sync_token: null,
  sales_webhook_url: null,
  salesperson_login: false,
  shop_logo: null,
  show_mrp_column_on_invoice: false,
  show_payment_mode_on_invoice: true,
  show_payment_page: true,
  skip_payment_page_on_invoice: false,
  stock_availability_check_for_sale: false,
  tax_preference: 'Excluding Tax',
  tax_region: 'India',
  token_no_next: null,
  token_no_prefix: null,
};

export const SHOP_SETTINGS = {
  other: {
    ...DEFAULT_SHOP_SETTINGS,
  },
  fine_dining: {
    ...DEFAULT_SHOP_SETTINGS,
    order_with_full_payment_only: false,
    enable_table_selection_pos: true,
  },
  pre_paid: {
    ...DEFAULT_SHOP_SETTINGS,
  },
  mini_mart: {
    ...DEFAULT_SHOP_SETTINGS,
    print_total_save_on_invoice: true,
    show_payment_mode_on_invoice: true,
    show_mrp_column_on_invoice: true,
  },
  delivery: {
    ...DEFAULT_SHOP_SETTINGS,
    order_with_full_payment_only: false,
  },
  take_away: {
    ...DEFAULT_SHOP_SETTINGS,
  },
};

export const SHOP_CATEGORIES = [{ restaurent: 'Restaurent' }, { supermarket: 'Supermarket' }, { other: 'Other' }];

export const SHOP_SUB_CATEGORIES = {
  restaurent: [{ 'fine-dining': 'Fine Dining' }, { 'pre-paid': 'Pre-paid' }, { other: 'Other' }],
  supermarket: [{ 'mini mart': 'Mini Mart' }, { delivery: 'Delivery' }, { other: 'Other' }],
  other: [{ 'take-away': 'Take Away' }, { delivery: 'Delivery' }, { other: 'Other' }],
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const SelectFilterStyle = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const SANOFT_WEB_URLS = {
  PRIVECY_POLICY: 'https://www.sanoft.com/privacy-policy',
  TERMS: 'https://www.sanoft.com/terms-and-conditions',
  REFUND_POLICY: 'https://www.sanoft.com/refund-policy',
  CONTACT_US: 'https://www.sanoft.com/#contact-us',
};

export const DEFAULT_COUNTRY_CODE = 'IN';

export const THEME_SANOFT = 'sanoft';

export const STATUS = {
  ACTIVE: 'ACTIVE',
  DE_ACTIVE: 'DE-ACTIVE',
};

export const RATE_DURATION = {
  HOURLY: 'hourly',
  DAILY: 'daily',
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
  ONE_TIME: 'oneTime',
};

export const SALE_STATUS = {
  DRAFT: 'DRAFT',
  CONFIRMED: 'CONFIRMED',
  RETURNED: 'RETURNED',
  PAID: 'PAID',
  CANCELLED: 'CANCELLED',
};