import Alert from '@mui/material/Alert';
import React, { useEffect, useState } from 'react';
import ShopsHelper from '../../helpers/ShopsHelper';
import RedirectToWhatsapp from '../utils/RedirectToWhatsapp';

/**
 * Display subscription ending soon alert if the current shop's subscription plan is going to end in the next 15 days
 * @param props - { shop }
 * @returns SubscriptionEndingSoonAlertView
 */
export default function SubscriptionEndingSoonAlert(props) {
  const { shop } = props;
  const [subscriptionRemainingDays, setSubscriptionRemainingDays] = useState();

  useEffect(() => {
    const remainingDays = ShopsHelper.getSubscriptionRemainingDays(shop);
    if (remainingDays < 15) {
      setSubscriptionRemainingDays(remainingDays);
    }
  }, [shop]);

  return (
    <div>
      {subscriptionRemainingDays && (
        <Alert severity="warning">
          Your subscription validity ends in {subscriptionRemainingDays} days.{' '}
          <strong>
            Click here to <RedirectToWhatsapp message="Renew subscription" />
          </strong>
        </Alert>
      )}
    </div>
  );
}
