import BackendService from './BackendService';

async function getSales(params = {}) {
  return BackendService.get('/sales/', { params });
}
async function getSale(id) {
  return BackendService.get(`/sales/${id}/`);
}
async function createSale(data) {
  return BackendService.post('/sales/', data);
}
async function updateSale(id, data = {}) {
  return BackendService.put(`/sales/${id}/`, data);
}
async function updateSalePartally(id, data = {}) {
  return BackendService.patch(`/sales/${id}/`, data);
}

const CustomerService = {
  getSales,
  getSale,
  createSale,
  updateSale,
  updateSalePartally,
};

export default CustomerService;
