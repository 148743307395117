import React, { createContext, useState, useEffect } from 'react';
import UserService from '../services/UserService';
export const UserContext = createContext();

export default function UserProvider(props) {

  const [user, setUser] = useState();

  const loadUserProfile = async () => {
    const resUser = await UserService.getUserProfile();
    if(!resUser) return ;

    setUser(resUser);
  };

  useEffect(() => {
    loadUserProfile();
  }, []);

  return <UserContext.Provider value={{ user, setUser, loadUserProfile }}>{props.children}</UserContext.Provider>;
}
