import CacheHelper from '../helpers/CacheHelper';
import BackendService from '../services/BackendService';

const cache = {};

async function getShops() {
  const cachedShops = CacheHelper.get(cache, 'shops');
  if (cachedShops) {
    return cachedShops;
  }

  const shops = await BackendService.get('/organizations/', { requireOrg: false });
  CacheHelper.store(cache, 'shops', shops);
  return shops;
}

const ShopService = {
  getShops,
};

export default ShopService;
