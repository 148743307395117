import BackendService from './BackendService';

async function getCustomers(params = {}) {
  return BackendService.get('/customers/', { params });
}
async function getCustomer(id) {
  return BackendService.get(`/customers/${id}/`);
}
async function getCustomerJournals(id, params) {
  return BackendService.get(`/customers/${id}/journals/`, {}, params);
}
async function createCustomer(data) {
  return BackendService.post('/customers/', data);
}
async function updateCustomer(id, data = {}) {
  return BackendService.put(`/customers/${id}/`, data);
}
async function deleteCustomer(id) {
  return BackendService.destroy(`/customer/${id}/`, '');
}
async function deleteCustomerPurchase(data) {
  return BackendService.destroy(`/sales/customer/`, data);
}
async function removeCustomerFromSale(id) {
  return BackendService.destroy(`/customers/unset-sale/${id}/`);
}
async function assignCustomer(data) {
  return BackendService.post('/sales/customer/', data);
}
async function getLastFourBill(id) {
  return BackendService.get(`/sales/customer/?customer_id=${id}`);
}
async function getCustomerSaleDetails(id) {
  return BackendService.get(`/sales/${id}/`);
}
async function addPaymentToCustomer(id, data) {
  return BackendService.post(`/customers/${id}/journals/`, data);
}
async function getCustomerLoyaltyLogs(id, data) {
  // return BackendService.post(`/customer/${id}/payment`, data);
  await new Promise((resolve) => {
    setTimeout(resolve, 3000);
  });
}

const CustomerService = {
  getCustomers,
  getCustomer,
  createCustomer,
  updateCustomer,
  deleteCustomer,
  assignCustomer,
  getLastFourBill,
  deleteCustomerPurchase,
  getCustomerJournals,
  getCustomerSaleDetails,
  removeCustomerFromSale,
  addPaymentToCustomer,
  getCustomerLoyaltyLogs,
};

export default CustomerService;
