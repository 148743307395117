import {
  Popper,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Divider,
  Button,
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import styles from './styles.module.css';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
// import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import PersonIcon from '@material-ui/icons/Person';
import { ROUTES, STORAGE_KEYS } from '../../const';
import AuthService from '../../services/AuthService';
import ShopSettingsService from '../../services/ShopSettingsService';
import { ShopContext } from '../../Context/ShopContext';
import SearchIcon from '@mui/icons-material/Search';

export default function AccountSwitchDialog(props) {
  const { accounts, anchorEI, isOpen } = props;
  const { setShop, shop } = useContext(ShopContext);
  const history = useHistory();
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [searchText, setSearchText] = useState('');
  const shopId = localStorage.getItem(STORAGE_KEYS.SHOP_ID);

  const loadShopProfile = async () => {
    const shopProfile = await ShopSettingsService.loadShopProfile();
    setShop(shopProfile);
  };

  const handleSwitchAccount = async (account) => {
    localStorage.removeItem(STORAGE_KEYS.SHOP_NAME);
    localStorage.setItem(STORAGE_KEYS.SHOP_ID, account.id);
    await loadShopProfile();
    window.location.reload();
    history.push(ROUTES.DASHBOARD);
  };
  const logoutBtnPressed = async (e) => {
    await AuthService.logout();
    window.location.reload();
  };

  const sortedAccounts = useCallback(
    () => (accounts || []).sort((a, b) => a.id - b.id),
    [accounts]
  );

  const handleSearch = (value) => {
    setSearchText(value);
    const filterResponse = accounts
      ? accounts.filter(
          (acc) =>
            acc.shop_name.toLowerCase().includes(value.toLowerCase()) ||
            acc.id.toString().includes(value)
        )
      : [];
    setFilteredAccounts(filterResponse);
  };

  useEffect(() => {
    const resp = sortedAccounts();
    setFilteredAccounts(resp);
  }, [sortedAccounts]);

  return (
    <Popper
      id="spring-popper"
      open={isOpen}
      anchorEl={anchorEI}
      placement="bottom"
      style={{ zIndex: 3, width: 300 }}
    >
      <Paper elevation={15} style={{ maxHeight: 400, overflowY: 'scroll' }}>
        <List>
          {shopId && shop && (
            <>
              <ListItem button key={shopId} className={styles.selectedProfile}>
                <ListItemAvatar key={shopId}>
                  <PersonIcon />
                </ListItemAvatar>
                <ListItemText primary={shop.name} />
              </ListItem>
              <Divider component="li" />
              {(accounts || []).length > 4 && (
                <ListItem
                  className={styles.selectedProfile}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <div className={styles.searchSec}>
                    <input
                      type="text"
                      value={searchText}
                      onChange={(e) => {
                        handleSearch(e.target.value);
                      }}
                      className={styles.searchInput}
                      placeholder="search shop"
                    />
                    <SearchIcon className={styles.searchIcon} />
                  </div>
                </ListItem>
              )}
              <Divider component="li" />
              {filteredAccounts &&
                filteredAccounts.map((account) => {
                  return (
                    <div key={account.id}>
                      {account.id.toString() !== shopId && (
                        <>
                          <ListItem
                            button
                            onClick={() => handleSwitchAccount(account)}
                            key={account.name}
                          >
                            <ListItemAvatar key={account.id}>
                              <PersonIcon />
                            </ListItemAvatar>
                            <ListItemText primary={account.name} />
                          </ListItem>
                          <Divider component="li" />
                        </>
                      )}
                    </div>
                  );
                })}
            </>
          )}
          {/* <ListItem>
            <Button
              variant="contained"
              size="small"
              fullWidth
              className={styles.actionBtn}
              style={{
                backgroundColor: '#605ca8',
                padding: '4px',
                color: '#fff',
              }}
              onClick={toggleAddShopDialog}
            >
              <AddBusinessIcon
                fontSize="small"
                className={styles.addShopIcon}
              />
              New Shop
            </Button>
          </ListItem> */}
          <ListItem>
            <Link
              to={ROUTES.LOGIN}
              style={{ textDecorationColor: 'transparent', width: '100%' }}
            >
              <Button
                variant="outlined"
                color="primary"
                size="small"
                fullWidth
                className={styles.actionBtn}
                onClick={logoutBtnPressed}
              >
                <PowerSettingsNewIcon
                  fontSize="small"
                  className={styles.actionBtnIcon}
                />
                Logout
              </Button>
            </Link>
          </ListItem>
        </List>
      </Paper>
    </Popper>
  );
}
