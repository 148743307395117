import { BACKEND_URL } from '../const';
import AuthService from './AuthService';
import ApiHelper from '../helpers/ApiHelper';

async function get(
  path,
  { headers = {}, params = {}, responseType = undefined, requireAuth = true, requireOrg = true } = {}
) {
  return _request(path, {
    method: 'GET',
    ..._genParams(headers, params, responseType),
    requireAuth,
    requireOrg,
  });
}

async function post(
  path,
  data,
  { headers, params, responseType = undefined, requireAuth = true, requireOrg = true } = {}
) {
  return _request(path, {
    method: 'POST',
    data,
    ..._genParams(headers, params, responseType),
    requireAuth,
    requireOrg,
  });
}

async function destroy(
  path,
  data,
  { headers, params, responseType = undefined, requireAuth = true, requireOrg = true } = {}
) {
  const resp = await _request(path, {
    method: 'DELETE',
    data,
    ..._genParams(headers, params, responseType),
    requireAuth,
    requireOrg,
  });
  return _getResponseData(resp);
}

async function put(path, data, { requireAuth = true, requireOrg = true } = {}) {
  const resp = await _request(path, {
    method: 'PUT',
    data,
    ..._genParams(),
    requireAuth,
    requireOrg,
  });
  return _getResponseData(resp);
}

async function patch(path, data, { requireAuth = true, requireOrg = true } = {}) {
  const resp = await _request(path, { method: 'PATCH', data, ..._genParams(), requireAuth, requireOrg });
  return _getResponseData(resp);
}

async function _request(path, config) {
  try {
    return await ApiHelper.request({ url: `${BACKEND_URL}${path}`, ...config });
  } catch (error) {
    _isInvalidTokenError(error) && (await _handleInvalidTokenError());
    throw error;
  }
}

function _genParams(headers = {}, params = {}, responseType = undefined) {
  return {
    headers,
    params,
    responseType,
  };
}

async function _getResponseData(resp) {
  return resp.data;
}

function _isInvalidTokenError(error) {
  return error?.response?.status === 401 || error?.response?.status === 403;
}

async function _handleInvalidTokenError() {
  await AuthService.logout();
  window.location.reload();
}

const BackendService = {
  get,
  post,
  put,
  patch,
  destroy,
};

export default BackendService;
