import { STORAGE_KEYS, BACKEND_URL } from '../const';
import ApiHelper from '../helpers/ApiHelper';

const login = async (data) => {
  const url = `${BACKEND_URL}/auth/login`;
  try {
    const response = await ApiHelper.request({
      url,
      method: 'POST',
      data,
    });

    if (!response.accessToken) {
      return new Error('Failed generate auth token from server');
    }

    localStorage.setItem(STORAGE_KEYS.ACCESS_TOKEN, response.accessToken);
    localStorage.setItem(STORAGE_KEYS.REFRESH_TOKEN, response.refreshToken);
    localStorage.setItem(STORAGE_KEYS.USER_ID, response.user.id);
  } catch (error) {
    return error;
  }
};

const getUserProfile = async () => {
  const url = `${BACKEND_URL}/auth/profile`;
  return ApiHelper.request({
    url,
    method: 'GET',
    requireAuth: true,
    requireOrg: false,
  });
};

const logout = async () => {
  const token = localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN);

  if (!token) {
    localStorage.clear();
    return;
  }

  const url = `${BACKEND_URL}/auth/logout`;
  await ApiHelper.request({
    url,
    method: 'DELETE',
    requireAuth: true,
  });

  localStorage.clear();
};

const AuthService = {
  login,
  logout,
  getUserProfile,
};

export default AuthService;
