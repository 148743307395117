import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import React from 'react';
import AlertMessagesProvider from 'react-alert-messages';
import './App.css';
import DashboardPage from './components/pages/Dashboard/Dashboard';
import SettingsPage from './components/pages/Settings/SettingsPage';
import ItemSummaryReportPage from './components/pages/Report/ItemSummary';
import PaymentModeSummaryReportPage from './components/pages/Report/PaymentModeSummaryReport';
import ItemisedBillSummaryReportPage from './components/pages/Report/ItemisedBill';
import BillSummaryReportPage from './components/pages/Report/BillSummary';
import ProfitBillSummaryReportPage from './components/pages/Report/ProfitBillSummary';
import ProfitItemSummaryReportPage from './components/pages/Report/ProfitItemSummary';
import ProfitItemisedBillSummaryReportPage from './components/pages/Report/ProfitItemisedBill';
import LoginPage from './components/pages/Login/Login';
import ForgetPasswordPage from './components/pages/Login/ForgetPassword';
import ItemsListPage from './components/pages/Products/ItemsList';
import ItemsCategoriesPage from './components/pages/Products/ItemsCategries';
import PermissionDetail from './components/pages/Settings/PermissionDetail';
import CustomersPage from './components/pages/Customers/Customers';
import PageNotFound from './components/pages/Error/PageNotFound';
import PrivateRoute from './components/utils/PrivateRoute';
import SelectedTabProvider from './Context/SelectedTabContext';
import ShopProvider from './Context/ShopContext';
import UserProvider from './Context/UserContext';
import { ROUTES } from './const';
import CustomerDetailsPage from './components/pages/Customers/CustomerDetails';
import ThemeProvider from './Context/ThemeContext';
import DynamicHeader from './components/utils/DynamicHeader/DynamicHeader';
import ActiveOrdersPage from './components/pages/ActiveOrders/ActiveOrders';
import ProductProvider from './Context/ProductContext';

function App() {
  return (
    <AlertMessagesProvider>
      <ThemeProvider>
        <DynamicHeader />
        <Router>
          <SelectedTabProvider>
            <Switch>
              <Route exact path={ROUTES.ANY} component={LoginPage} />
              <Route exact path={ROUTES.CONSOLE} component={LoginPage} />
              <Route exact path={ROUTES.LOGIN} component={LoginPage} />
              <Route exact path={ROUTES.RESET_PASSWORD} component={ForgetPasswordPage} />
              <Route exact path={ROUTES.PAGE_404} component={PageNotFound} />
              <ShopProvider>
                <UserProvider>
                  <ProductProvider>
                    <PrivateRoute exact path={ROUTES.DASHBOARD} component={DashboardPage} />
                    <PrivateRoute exact path={ROUTES.EDIT_SALE} component={DashboardPage} />
                    <PrivateRoute exact path={ROUTES.ACTIVE_ORDERS} component={ActiveOrdersPage} />
                    <PrivateRoute exact path={ROUTES.ITEMS} component={ItemsListPage} />
                    <PrivateRoute exact path={ROUTES.CATEGORIES} component={ItemsCategoriesPage} />
                    <PrivateRoute exact path={ROUTES.CUSTOMERS} component={CustomersPage} />
                    <PrivateRoute exact path={ROUTES.CUSTOMER_DETAILS} component={CustomerDetailsPage} />
                    <PrivateRoute exact path={ROUTES.ITEM_SUMMARY} component={ItemSummaryReportPage} />
                    <PrivateRoute exact path={ROUTES.ITEMISED_BILL} component={ItemisedBillSummaryReportPage} />
                    <PrivateRoute exact path={ROUTES.BILL_SUMMARY} component={BillSummaryReportPage} />
                    <PrivateRoute exact path={ROUTES.PAYMENT_MODE_REPORT} component={PaymentModeSummaryReportPage} />
                    <PrivateRoute exact path={ROUTES.PROFIT_ITEM_SUMMARY} component={ProfitItemSummaryReportPage} />
                    <PrivateRoute
                      exact
                      path={ROUTES.PROFIT_ITEMISED_BILL}
                      component={ProfitItemisedBillSummaryReportPage}
                    />
                    <PrivateRoute exact path={ROUTES.PROFIT_BILL_SUMMARY} component={ProfitBillSummaryReportPage} />
                    <PrivateRoute exact path={ROUTES.SETTINGS} component={SettingsPage} />
                    <PrivateRoute exact path={ROUTES.SETTINGS_TAB} component={SettingsPage} />
                    <PrivateRoute exact path={ROUTES.SETTINGS_SUB_TAB} component={SettingsPage} />
                    <PrivateRoute exact path={ROUTES.PERMISSION_DETAIL} component={PermissionDetail} />
                  </ProductProvider>
                </UserProvider>
              </ShopProvider>

              <Route path="/*">
                <Redirect to={ROUTES.PAGE_404} />
              </Route>
            </Switch>
          </SelectedTabProvider>
        </Router>
      </ThemeProvider>
    </AlertMessagesProvider>
  );
}

export default App;
