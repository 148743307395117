import { Grid } from '@material-ui/core';
import styles from './styles.module.css';
// import BillFormat from './BillFormat';
// import ShopSettings from './ShopSettings';
import ShopProfile from './ShopProfile';
import React, { useState } from 'react';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
// import PaymentModes from './PaymentModes';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTES } from '../../../const';

const tabStyleActive = {
  backgroundColor: '#FC9A02',
  color: '#fff',
};

const tabStyle = {
  color: '#000',
};

const NAVIGATION_TAB = {
  INVOICE_FORMAT: 'invoice-format',
  SETTINGS: 'settings',
  PROFILE: 'profile',
  PAYMENT_MODES: 'payment-modes',
};

function SettingsPage(props) {
  const { tab } = useParams();
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(tab ?? NAVIGATION_TAB.PROFILE);

  const handleTabSelection = (tab) => {
    history.push(ROUTES.SETTINGS_TAB.replace(':tab', tab));
    setSelectedTab(tab);
  };

  return (
    <div className={styles.contentsArea}>
      <div className={styles.headingDiv}>
        <span className={styles.pageHead}>Settings</span>
      </div>
      <div className={styles.changeable}>
        <Grid className={styles.settingsTab} container>
          <div
            className={styles.navItem}
            style={
              selectedTab === NAVIGATION_TAB.PROFILE ? tabStyleActive : tabStyle
            }
            onClick={() => {
              handleTabSelection(NAVIGATION_TAB.PROFILE);
            }}
          >
            <span className={styles.title}>Profile</span>
          </div>
          {/* <div
            className={styles.navItem}
            style={
              selectedTab === NAVIGATION_TAB.INVOICE_FORMAT
                ? tabStyleActive
                : tabStyle
            }
            onClick={() => {
              handleTabSelection(NAVIGATION_TAB.INVOICE_FORMAT);
            }}
          >
            <span className={styles.title}>Invoice format</span>
          </div> */}
          {/* <div
            className={styles.navItem}
            style={
              selectedTab === NAVIGATION_TAB.PAYMENT_MODES
                ? tabStyleActive
                : tabStyle
            }
            onClick={() => {
              handleTabSelection(NAVIGATION_TAB.PAYMENT_MODES);
            }}
          >
            <span className={styles.title}>Payment Modes</span>
          </div> */}
          {/* <div
            className={styles.navItem}
            style={
              selectedTab === NAVIGATION_TAB.SETTINGS
                ? tabStyleActive
                : tabStyle
            }
            onClick={() => {
              handleTabSelection(NAVIGATION_TAB.SETTINGS);
            }}
          >
            <span className={styles.title}>Settings</span>
          </div> */}
        </Grid>

        {/* {selectedTab === NAVIGATION_TAB.INVOICE_FORMAT && <BillFormat />}
        {selectedTab === NAVIGATION_TAB.SETTINGS && <ShopSettings />} */}
        {selectedTab === NAVIGATION_TAB.PROFILE && <ShopProfile />}
        {/* {selectedTab === NAVIGATION_TAB.PAYMENT_MODES && <PaymentModes />} */}
      </div>
    </div>
  );
}

export default withConsoleBase(SettingsPage);
