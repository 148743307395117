class DateTimeHelpers {
  getTodayStr() {
    const now = new Date();
    const d = now.getDate();
    const m = now.getMonth() + 1;
    const y = now.getFullYear();
    return `${y}/${m <= 9 ? `0${m}` : m}/${d <= 9 ? `0${d}` : d}`;
  }
  convertDateToMDY(date) {
    const dateParts = date.split('/');
    return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
  }

  //deprecated against `convertDateToIsoDMY`
  convertDateToDMY(date) {
    const d = date.getDate();
    const m = date.getMonth() + 1;
    const y = date.getFullYear();
    return `${d <= 9 ? `0${d}` : d}/${m <= 9 ? `0${m}` : m}/${y}`;
  }

  convertDateToIsoDMY(date) {
    const d = date.getDate();
    const m = date.getMonth() + 1;
    const y = date.getFullYear();
    return `${d <= 9 ? `0${d}` : d}-${m <= 9 ? `0${m}` : m}-${y}`;
  }

  covertDateToJsDate(date) {
    const dateParts = date.toString().split('/');
    const dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    return dateObject.toISOString();
  }

  getDate(dateString) {
    const date = new Date(dateString);
    return this.convertDateToIsoDMY(date);
  }

  getTime(dateString) {
    const date = new Date(dateString);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();
    return `${hours}:${minutes}:${seconds}`;
  }

  getTodayStrInYMD() {
    const now = new Date();
    return this.getDateYMD(now);
  }
  getWeekStr() {
    const now = new Date();
    now.setDate(now.getDate() - 7);
    return this.getDateYMD(now);
  }
  getMonthStr() {
    const now = new Date();
    now.setMonth(now.getMonth() - 1);
    return this.getDateYMD(now);
  }
  getSixMonthStr() {
    const now = new Date();
    now.setMonth(now.getMonth() - 6);
    return this.getDateYMD(now);
  }

  getDateYMD(date) {
    const d = date.getDate();
    const m = date.getMonth() + 1;
    const y = date.getFullYear();
    return `${y}-${m <= 9 ? `0${m}` : m}-${d <= 9 ? `0${d}` : d}`;
  }

  formatServerTimeSegment(timeString) {
    const [hours, minutes, seconds] = timeString.split(':');
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);
    const timeOptions = {
      hour12: true,
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };
    return date.toLocaleTimeString(undefined, timeOptions).toUpperCase();
  }

  startOfDay(dateString) {
    const startDate = new Date(dateString);
    startDate.setHours(0, 0, 0, 1);
    return startDate.toISOString();
  }

  endOfDay(dateString) {
    const endDate = new Date(dateString);
    endDate.setHours(23, 59, 59, 999);
    return endDate.toISOString();
  }

  getTime12HMormat(dateString) {
    const date = new Date(dateString);
    const timeOptions = {
      hour12: true,
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };
    return date.toLocaleTimeString(undefined, timeOptions).toUpperCase();
  }

  reportDateLimit(startDate, endDate) {
    const dateDifference =
      (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());

    return dateDifference > 3;
  }

  getOneDay(dateString) {
    const date = new Date(dateString);
    date.setUTCDate(date.getUTCDate() + 1);
    return date;
  }

  getTimeDiff(date) {
    const now = new Date();
    const diffInMilliseconds = now - new Date(date);

    const diffInMinutes = Math.floor(diffInMilliseconds / 60000);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    let hours = diffInHours % 24;
    let minutes = diffInMinutes % 60;

    if (minutes >= 15) {
      hours += 1;
    }

    if (hours === 24) {
      hours = 0;
      return `${diffInDays + 1}d/0h`;
    }

    return `${diffInDays}d/${hours}h`;
  }

  getMaxTimeOfDate = (date) => {
    const maxDate = new Date(date);
    maxDate.setHours(23, 59, 59);
    return new Date(maxDate);
  };
  getMinTimeOfDate = (date) => {
    const minDate = new Date(date);
    minDate.setHours(0, 0, 0);
    return new Date(minDate);
  }
}

const dateTimeHelper = new DateTimeHelpers();
export default dateTimeHelper;
