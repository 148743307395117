import React, { useState, useEffect, useContext, useCallback } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Button } from '@material-ui/core';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable/';
import EditCategoryDialog from '../../popups/EditCategoryDialog';
import AddCategoryDialog from '../../popups/AddCategoryDialog';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import ItemService from '../../../services/ItemService';
import ListDialog from '../../popups/ListDialog';
import Loader from '../../utils/Loading';
import setDelay from '../../../helpers/LoadingDelay';
import Info from '../../utils/Alert/Info';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import { AlertMessagesContext } from 'react-alert-messages';
import { ProductContext } from '../../../Context/ProductContext';

function ItemsCategories() {
  const { items } = useContext(ProductContext);
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const [categories, setCategories] = useState();
  const [categoriesFiltered, setCategoriesFiltered] = useState();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isShowCreateFormDialog, setShowCreateFormDialog] = useState(false);
  const [itemsData, setItemsData] = useState('');
  const [searchText, setSearchText] = useState('');
  const [loadingIndicator, setLoadingIndicator] = useState(false);

  const handleEditFormDialogClose = () => {
    setSelectedCategory(null);
  };

  const handleCreateFormDialogClose = () => {
    setShowCreateFormDialog(false);
  };

  const onCategoryUpdateSuccess = () => {
    getCategories();
  };

  const onCategorySelected = (category) => {
    setSelectedCategory(category);
  };

  const toggleItemsTab = (rowData) => {
    if (!rowData) {
      setItemsData('');
    } else {
      setItemsData(items.filter((item) => item.categoryId === rowData.id));
    }
  };

  const handleOpenCreateFormDialog = () => {
    setShowCreateFormDialog(true);
  };

  const getCategories = useCallback(async () => {
    setLoadingIndicator(true);
    try {
      const res = await ItemService.getItemCategories();
      setCategories(res);
      setCategoriesFiltered(res);
    } catch (err) {
      postAlertMessage({
        text: err.message,
        type: 'failed',
      });
    }
    setDelay(setLoadingIndicator);
  }, [postAlertMessage]);

  const handleCategorySearch = (value) => {
    setSearchText(value);
    setCategoriesFiltered(categories.filter((category) => category.name.toLowerCase().includes(value.toLowerCase())));
  };

  const headerData = [
    {
      label: 'Category Name',
      id: 'name',
      type: 'text',
    },
    {
      label: 'Category Image',
      id: 'image',
      type: 'image',
    },
    {
      label: 'View Products',
      id: 'viewItems',
      type: 'button',
      title: 'View Products',
      clickHandler: toggleItemsTab,
    },
    {
      label: 'Edit Category',
      id: 'updateCategory',
      type: 'button',
      title: 'Edit Category',
      clickHandler: onCategorySelected,
    },
  ];

  const getItemRate = (item) => {
    if (!item) return;
    const { hourly, daily, monthly, yearly, oneTime } = item?.rent;

    return [
      { value: hourly, label: '/h', key: 'hourly' },
      { value: daily, label: '/d', key: 'daily' },
      { value: monthly, label: '/m', key: 'monthly' },
      { value: yearly, label: '/y', key: 'yearly' },
      { value: oneTime, label: '/ot', key: 'oneTime' },
    ].filter((rate) => rate.value);
  };

  const listHeader = [
    {
      label: 'Item Name',
      id: 'name',
      type: 'text',
    },
    {
      label: 'Stock',
      id: 'stock',
      type: 'text',
    },
    {
      label: 'Maintenance',
      id: 'stockInMaintenance',
      type: 'text',
    },
    {
      label: 'Rate',
      id: 'rate',
      type: 'callback',
      viewRender: (item) => {
        return (getItemRate(item) || []).map((rate) => `${rate.value}${rate.label}`).join(' + ') ?? '-';
      },
    },
  ];

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />

      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          Items<span className={styles.menuTitle}>Category</span>
        </h2>
      </div>
      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}>
            <h2 className={styles.subTitle}>Categories</h2>
          </div>
          <div className={styles.filerInputSec}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={styles.actionBtn}
              style={{ backgroundColor: '#00a65a' }}
              onClick={handleOpenCreateFormDialog}
            >
              <AddShoppingCartIcon className={styles.actionBtnIcon} />
              New category
            </Button>
            <div className={styles.searchSec} style={{ padding: 5 }}>
              <input
                type="text"
                value={searchText}
                placeholder="search category"
                onChange={(e) => {
                  handleCategorySearch(e.target.value);
                }}
                className={styles.searchInput}
              />
              <SearchIcon className={styles.searchIcon} />
            </div>
          </div>
        </div>
      </div>
      {categories &&
        (categoriesFiltered && categoriesFiltered.length ? (
          <DataTable columns={headerData} rows={categoriesFiltered ? categoriesFiltered : categories} />
        ) : (
          <Info title={'You have no categories to list'} content={"You haven't categories with the given properties"} />
        ))}

      {selectedCategory && (
        <EditCategoryDialog
          category={selectedCategory}
          handleClose={handleEditFormDialogClose}
          onSuccess={onCategoryUpdateSuccess}
        />
      )}

      {isShowCreateFormDialog && (
        <AddCategoryDialog handleClose={handleCreateFormDialogClose} onSuccess={onCategoryUpdateSuccess} />
      )}

      {itemsData && (
        <ListDialog
          toggleItemsTab={toggleItemsTab}
          rowData={itemsData}
          tableHeader={listHeader}
          title={'Category Products'}
          emptyDataTitle="You have an empty list of items for this category!"
          emptyDataContent="Start adding your items for this category by editing this category"
        />
      )}
    </div>
  );
}

export default withConsoleBase(ItemsCategories);
