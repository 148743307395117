import React, { useEffect, useState, useContext, useCallback } from 'react';
import styles from './styles.module.css';
import { useHistory } from 'react-router-dom';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import Loader from '../../utils/Loading';
import SaleService from '../../../services/SaleService';
import { AlertMessagesContext } from 'react-alert-messages';
import { Paper } from '@mui/material';
import DataTable from '../../utils/DataTable';
import Info from '../../utils/Alert/Info';
import DateTimeHelper from '../../../helpers/DateTimeHelpers';
import shortDateDiff from 'short-date-diff';
import { ROUTES } from '../../../const';
import { Button } from '@material-ui/core';
import { ProductContext } from '../../../Context/ProductContext';
import getSaleTotal from '../../utils/getSaleTotal';
import { Typography } from '@mui/material';

function ActiveOrders() {
  const { postAlertMessage } = useContext(AlertMessagesContext);
  const { items } = useContext(ProductContext);
  const history = useHistory();

  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [activeSales, setActiveSales] = useState([]);
  const [filterSales, setFilterSales] = useState([]);
  const [filterText, setFilterText] = useState('');

  const getActiveSales = useCallback(async () => {
    setLoadingIndicator(true);
    try {
      const resp = await SaleService.getSales({ active: true });
      setActiveSales(resp);
      setFilterSales(resp);
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  }, [postAlertMessage]);

  const headerData = [
    {
      label: 'ID',
      id: 'code',
      type: 'text',
    },
    {
      label: 'Customer',
      id: 'customer',
      type: 'text',
    },
    {
      label: 'Items',
      id: 'item',
      type: 'callback',
      viewRender: (sale) => {
        return sale.items.length;
      },
    },
    {
      label: 'Date',
      id: 'startDate',
      type: 'callback',
      viewRender: (sale) => {
        return (
          <span>
            {DateTimeHelper.convertDateToDMY(new Date(sale.startDate))}
            <br />
            {DateTimeHelper.getTime12HMormat(new Date(sale.startDate))}
          </span>
        );
      },
    },
    {
      label: 'Return',
      id: 'returnDate',
      type: 'callback',
      viewRender: (sale) => {
        if (!sale.returnDate) return '-';
        return (
          <span>
            {DateTimeHelper.convertDateToDMY(new Date(sale.returnDate))}
            <br />
            {DateTimeHelper.getTime12HMormat(new Date(sale.returnDate))}
          </span>
        );
      },
    },
    {
      label: 'Advance',
      id: 'advanceAmount',
      type: 'text',
    },
    {
      label: 'Paid',
      id: 'paidAmount',
      type: 'text',
    },
    {
      label: 'Total',
      id: 'totalAmount',
      type: 'callback',
      viewRender: (data) => getSaleTotal(data, items),
    },
    {
      label: 'Remaining',
      id: 'remainingAmoutn',
      type: 'callback',
      viewRender: (data) => getSaleRemainingAmount(data),
    },
    {
      label: 'Days',
      id: 'day',
      type: 'callback',
      viewRender: (data) => getPurchaseDays(data.startDate),
    },
  ];

  const getSaleRemainingAmount = (sale) => {
    const amount = getSaleTotal(sale, items);
    return (
      <Typography variant="body2" style={{ color: '#5555f5' }} gutterBottom>
        {amount - sale?.advanceAmount}
      </Typography>
    );
  };

  const getPurchaseDays = (startDate) => {
    const start = new Date(startDate);
    const now = new Date();

    return shortDateDiff(start, now);
  };

  const handleSearch = (value) => {
    setFilterText(value);
    setFilterSales(
      activeSales.filter(
        (sale) => sale.code.toString().includes(value.toLowerCase()) || sale.customer.includes(value.toLowerCase())
      )
    );
  };

  const moveToEditSale = (row) => {
    history.push(ROUTES.EDIT_SALE.replace(':saleId', row.id));
  };

  useEffect(() => {
    getActiveSales();
  }, [getActiveSales]);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <div className={styles.titleSec}>
        <span className={styles.title}>
          Active<span className={styles.menuTitle}>Orders</span>
        </span>
      </div>

      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.filerInputSec}>
            <div className={styles.searchSec}>
              <input
                type="text"
                value={filterText}
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
                className={styles.searchInput}
                placeholder="Search by id, customer"
              />
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={styles.actionBtn}
                style={{ backgroundColor: '#00a65a' }}
                onClick={() => {
                  getActiveSales();
                }}
              >
                Refresh
              </Button>
            </div>
          </div>
        </div>
      </div>

      {filterSales && filterSales.length ? (
        <Paper className={styles.tableWrapper}>
          <DataTable columns={headerData} rows={filterSales} rowClickListener={moveToEditSale} />
          {/* <TablePagination
            rowsPerPageOptions={[20, 50]}
            component="div"
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={() => ''}
          /> */}
        </Paper>
      ) : (
        !loadingIndicator && (
          <Info
            title={'No active sales to list'}
            content={
              'You have no active sales to list with current filter configuration. Please clear the filters or create a sale'
            }
          />
        )
      )}
    </div>
  );
}

export default withConsoleBase(ActiveOrders);
