import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Button } from '@material-ui/core';
import styles from './styles.module.css';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import PrintIcon from '@mui/icons-material/Print';
import SaleService from '../../../services/SaleService';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import DataTable from '../../utils/DataTable';
import Info from '../../utils/Alert/Info';
import Loader from '../../utils/Loading';
import { AlertMessagesContext } from 'react-alert-messages';
import { ShopContext } from '../../../Context/ShopContext';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import SyncIcon from '@mui/icons-material/Sync';
import { filterEndDate } from '../../utils/FilterEndDate';
import ShopsHelper from '../../../helpers/ShopsHelper';
import shortDateDiff from 'short-date-diff';
import SearchIcon from '@material-ui/icons/Search';
import Grayout from '../../utils/GrayOut/Grayout';

function BillSummaryReport() {
  const { postAlertMessage } = useContext(AlertMessagesContext);
  const { shop } = useContext(ShopContext);

  const [filterFromDate, setFilterFromDate] = useState(getDefaultStartTime());
  const [filterToDate, setFilterToDate] = useState(filterEndDate());
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [prevFilterData, setPrevFilterData] = useState({
    filterFromDate,
    filterToDate,
  });
  const [items, setItems] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [isShowGenerateButton, setIsShowGenerateButton] = useState(null);

  const loadBillSummaryReports = async ({ startTime = filterFromDate, endTime = filterToDate } = {}) => {
    setLoadingIndicator(true);
    try {
      const timezoneFreeStartTime = new Date(startTime.getTime() - startTime.getTimezoneOffset() * 60000);
      const timezoneFreeEndTime = new Date(endTime.getTime() - endTime.getTimezoneOffset() * 60000);
      const data = {
        filterStartTime: timezoneFreeStartTime,
        filterEndTime: timezoneFreeEndTime,
        active: false,
      };
      const resp = await SaleService.getSales(data);
      setItems(resp);
      setFilteredItems(resp);
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  };

  const handleSearch = (value) => {
    setSearchText(value);
    setFilteredItems(
      items.filter(
        (item) => item.code.toString().includes(value.toLowerCase()) || item.customer.includes(value.toLowerCase())
      )
    );
  };

  const _getBillReportSummary = (items) => {
    let totalAmount = 0;
    let totalTax = 0;
    let discount = 0;
    items.forEach((item) => {
      totalAmount += item.totalAmount;
      totalTax += item.totalTax;
      discount += item.discount;
    });

    return {
      totalAmount: ShopsHelper.getAmountFormatted(shop, totalAmount),
      totalTax: ShopsHelper.getAmountFormatted(shop, totalTax),
      discount: ShopsHelper.getAmountFormatted(shop, discount),
    };
  };

  function getDefaultStartTime() {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  }

  const headerData = [
    {
      label: 'ID',
      id: 'code',
      type: 'text',
    },
    {
      label: 'Customer',
      id: 'customer',
      type: 'text',
    },
    {
      label: 'Items',
      id: 'item',
      type: 'callback',
      viewRender: (sale) => {
        return sale.items.length;
      },
    },
    {
      label: 'Date',
      id: 'startDate',
      type: 'callback',
      viewRender: (sale) => {
        return (
          <span>
            {DateTimeHelpers.convertDateToDMY(new Date(sale.startDate))}
            <br />
            {DateTimeHelpers.getTime12HMormat(new Date(sale.startDate))}
          </span>
        );
      },
    },
    {
      label: 'Return',
      id: 'Return',
      type: 'callback',
      viewRender: (sale) => {
        return (
          <span>
            {DateTimeHelpers.convertDateToDMY(new Date(sale.returnDate))}
            <br />
            {DateTimeHelpers.getTime12HMormat(new Date(sale.returnDate))}
          </span>
        );
      },
    },
    {
      label: 'Bill Date',
      id: 'billDate',
      type: 'callback',
      viewRender: (sale) => {
        return sale?.billDate ? (
          <span>
            {DateTimeHelpers.convertDateToDMY(new Date(sale.billDate))}
            <br />
            {DateTimeHelpers.getTime12HMormat(new Date(sale.billDate))}
          </span>
        ) : (
          '-'
        );
      },
    },
    {
      label: 'Advance',
      id: 'advanceAmount',
      type: 'text',
    },
    {
      label: 'Paid',
      id: 'paidAmount',
      type: 'text',
    },
    {
      label: 'Tax',
      id: 'totalTax',
      type: 'text',
    },
    {
      label: 'Total',
      id: 'totalAmount',
      type: 'text',
    },
    {
      label: 'Days',
      id: 'day',
      type: 'callback',
      viewRender: (data) => getPurchaseDays(data),
    },
  ];

  const getPurchaseDays = (data) => {
    const start = new Date(data.startDate);
    const end = new Date(data.returnDate);

    return shortDateDiff(start, end);
  };

  const isAnyChangeOnReportsFilters = useCallback(() => {
    return filterFromDate !== prevFilterData.filterFromDate || filterToDate !== prevFilterData.filterToDate;
  }, [filterFromDate, filterToDate, prevFilterData.filterFromDate, prevFilterData.filterToDate]);

  const generateSummeryReport = () => {
    loadBillSummaryReports();
    setIsShowGenerateButton(false);
    setPrevFilterData({
      filterFromDate,
      filterToDate,
    });
  };

  useEffect(() => {
    generateSummeryReport();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isShowGenerateButton === null) return;
    setIsShowGenerateButton(isAnyChangeOnReportsFilters());
  }, [isAnyChangeOnReportsFilters, isShowGenerateButton]);

  const summary = _getBillReportSummary(filteredItems);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />

      <div className={styles.titleSec}>
        <span className={styles.title}>
          Reports<span className={styles.menuTitle}>Generation</span>
        </span>
        <div style={{ justifyContent: 'flex-end', marginRight: '10px' }}>
          <div style={{ paddingBottom: '4px' }}>
            <label className={styles.label}>Print Report</label>
          </div>
          <Button
            variant="contained"
            color="primary"
            className={styles.actionBtn}
            style={{ backgroundColor: '#00a65a' }}
            onClick={window.print}
          >
            <PrintIcon className={styles.actionBtnIcon} />
            Print
          </Button>
        </div>
      </div>

      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}>
            <h2 className={styles.subTitle}>
              Sales Summary Report for {DateTimeHelpers.convertDateToDMY(filterFromDate)} to{' '}
              {DateTimeHelpers.convertDateToDMY(filterToDate)}
            </h2>
          </div>
          <div className={styles.filerInputSec}>
            <div className={styles.searchSec}>
              <input
                type="text"
                value={searchText}
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
                className={styles.searchInput}
                placeholder="Search by id, customer"
              />
              <SearchIcon className={styles.searchIcon} />
            </div>
          </div>
        </div>
        <div className={styles.actionButtons}>
          <div className={styles.dateDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>From</label>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  format="dd/MM/yyyy"
                  id="date-picker-from"
                  className={styles.dateBox}
                  value={filterFromDate}
                  onChange={(date) => {
                    setFilterFromDate(date);
                    // loadBillSummaryReports({ startTime: date });
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Change date',
                  }}
                />
                <KeyboardTimePicker
                  id="time-picker-from"
                  className={styles.dateBox}
                  value={filterFromDate}
                  onChange={(date) => {
                    setFilterFromDate(date);
                    // loadBillSummaryReports({ startTime: date });
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Change Time',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className={styles.dateDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>To</label>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  format="dd/MM/yyyy"
                  id="date-picker-to"
                  className={styles.dateBox}
                  value={filterToDate}
                  onChange={(date) => {
                    setFilterToDate(date);
                    // loadBillSummaryReports({ endTime: date });
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Change date',
                  }}
                />
                <KeyboardTimePicker
                  id="time-picker-to"
                  className={styles.dateBox}
                  value={filterToDate}
                  onChange={(date) => {
                    setFilterToDate(date);
                    // loadBillSummaryReports({ endTime: date });
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Change Time',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Generate Report</label>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                className={styles.actionBtn}
                style={{ backgroundColor: '#00a65a' }}
                onClick={generateSummeryReport}
                disabled={isShowGenerateButton === false}
              >
                <SyncIcon className={styles.actionBtnIcon} />
                Generate
              </Button>
            </div>
          </div>
        </div>
      </div>
      {isShowGenerateButton === null ? (
        <Info
          title={'Set filters and click generate button'}
          content={
            'Reports are generated based on the filters. Please set filters and click generate button to the generate report.'
          }
        />
      ) : (
        items &&
        (filteredItems && filteredItems.length ? (
          <div className={styles.tableContainer}>
            <Grayout open={isShowGenerateButton} />
            <DataTable
              columns={headerData}
              rows={filteredItems ? filteredItems : items}
              rowKey="id"
              summary={{
                date: 'Total',
                total: `${shop?.currency || ''} ${summary.totalAmount}`,
                tax_amount: `${shop?.currency || ''} ${summary.totalTax}`,
                cess_amount: `${shop?.currency || ''} ${summary.totalAdditionalTax}`,
                discount_applied_on_items: `${shop?.currency || ''}  ${summary.discount}`,
              }}
            />
          </div>
        ) : (
          !loadingIndicator && (
            <Info
              severity="warning"
              title={'Oh no, there are no sales matching to your filter'}
              content={'There are no sales matching to your filter. Please verify your inputs to generate report.'}
            />
          )
        ))
      )}
    </div>
  );
}

export default withConsoleBase(BillSummaryReport);
