import BackendService from './BackendService';
import CacheHelper from '../helpers/CacheHelper';
import { STORAGE_KEYS } from '../const';

const cache = {};

async function getUserProfile(userId = localStorage.getItem(STORAGE_KEYS.USER_ID)) {
  if (!userId) return;

  // TODO: Get user profile directly from auth token

  const cacheKey = `shop-${userId}-user-profile`;
  const cachedUserProfile = CacheHelper.get(cache, cacheKey);
  if (cachedUserProfile) {
    return cachedUserProfile;
  }

  const userProfile = await BackendService.get(`/users/${userId}`, {
    requireOrg: false,
  });

  CacheHelper.store(cache, cacheKey, userProfile);
  return userProfile;
}
async function getShopUsers() {
  return BackendService.get('/shop_users/');
}
async function createNewUser(data) {
  return BackendService.post('/shop_users/', data);
}
async function getShopUserDetailsAndPermissions(id) {
  return BackendService.get(`/shop_users/${id}/`);
}
async function deleteUser(id) {
  return BackendService.destroy(`/shop_users/${id}/`);
}
async function UpdateUserPermissions(data, id) {
  return BackendService.post(`/shop_users/${id}/`, data);
}

const UserService = {
  getUserProfile,
  getShopUsers,
  createNewUser,
  getShopUserDetailsAndPermissions,
  UpdateUserPermissions,
  deleteUser,
};

export default UserService;
