import React, { createContext, useEffect, useState } from 'react';
import ShopSettingsService from '../services/ShopSettingsService';

export const ShopContext = createContext();

function ShopProvider(props) {
  const [shop, setShop] = useState();

  const loadShopProfile = async () => {
    const res = await ShopSettingsService.loadShopProfile();
    setShop(res);
  };

  useEffect(() => {
    loadShopProfile();
  }, []);
  
  return (
    <ShopContext.Provider
      value={{
        shop,
        setShop,
        loadShopProfile,
      }}
    >
      {props.children}
    </ShopContext.Provider>
  );
}

export default ShopProvider;
