import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import ItemService from '../services/ItemService';
import { ShopContext } from './ShopContext';

export const ProductContext = createContext();

function ProductProvider(props) {
  const { shop } = useContext(ShopContext);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchItems = useCallback(async () => {
    setIsLoading(true);
    const items = await ItemService.getItems();
    setItems(items);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (shop) {
      fetchItems();
    }
  }, [fetchItems, shop]);

  return (
    <ProductContext.Provider
      value={{
        items,
        setItems,
        fetchItems,
        isLoading,
      }}
    >
      {props.children}
    </ProductContext.Provider>
  );
}

export default ProductProvider;
