import { Button, MenuItem, TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';
import ItemService from '../../services/ItemService';
import { DialogActions, Grid } from '@mui/material';
import { AlertMessagesContext } from 'react-alert-messages';
import { STATUS } from '../../const';

export default function AddItemDialog(props) {
  const { categories, onSuccess, handleClose } = props;

  const { postAlertMessage } = useContext(AlertMessagesContext);

  const [name, setName] = useState('');
  const [stock, setStock] = useState(null);
  const [categoryId, setCategoryId] = useState('');
  const [status, setStatus] = useState(true);
  const [order, setOrder] = useState(null);
  const [stockInMaintenance, setStockInMaintenance] = useState(0);
  const [hourly, setHourly] = useState(null);
  const [daily, setDaily] = useState(null);
  const [monthly, setMonthly] = useState(null);
  const [yearly, setYearly] = useState(null);
  const [oneTime, setOneTime] = useState(null);
  const [tax, setTax] = useState(null)

  const handleAddItem = async () => {
    const data = {
      categoryId,
      name,
      stock: stock ? Number(stock) : 0,
      status: status ? STATUS.ACTIVE : STATUS.DE_ACTIVE,
      order: order ? Number(order) : null,
      stockInMaintenance: stockInMaintenance ? Number(stockInMaintenance) : 0,
      tax: tax ? Number(tax) : 0,
      rent: {
        hourly: hourly ? Number(hourly) : null,
        daily: daily ? Number(daily) : null,
        monthly: monthly ? Number(monthly) : null,
        yearly: yearly ? Number(yearly) : null,
        oneTime: oneTime ? Number(oneTime) : null,
      },
    };
    try {
      await ItemService.addItem(data);
      postAlertMessage({
        text: 'Item created successfully',
        type: 'success',
      });
      onSuccess();
      handleClose();
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
  };

  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>Create Item</h2>
          <CloseIcon onClick={handleClose} />
        </div>
        <div className={styles.inputSec}>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} display={'flex'} justifyContent="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Name</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Name"
                variant="outlined"
                size="small"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} display={'flex'} justifyContent="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Category</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                select
                name="category"
                size="small"
                variant="outlined"
                color="primary"
                value={categoryId}
                onChange={(e) => setCategoryId(e.target.value)}
                fullWidth
              >
                {categories.map((category) => (
                  <MenuItem value={category.id}>{category.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} display={'flex'} justifyContent="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Order</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Order"
                variant="outlined"
                size="small"
                type="number"
                value={order}
                onChange={(e) => setOrder(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} display={'flex'} justifyContent="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Stock</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Stock"
                variant="outlined"
                size="small"
                type="number"
                value={stock}
                onChange={(e) => setStock(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} display={'flex'} justifyContent="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Maintenance</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Maintenance"
                variant="outlined"
                size="small"
                type="number"
                value={stockInMaintenance}
                onChange={(e) => setStockInMaintenance(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} display={'flex'} justifyContent="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Tax</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Tax"
                variant="outlined"
                size="small"
                type="number"
                value={tax}
                onChange={(e) => setTax(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} display={'flex'} justifyContent="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Hourly</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Hourly"
                variant="outlined"
                size="small"
                type="number"
                value={hourly}
                onChange={(e) => setHourly(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} display={'flex'} justifyContent="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Daily</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Daily"
                variant="outlined"
                size="small"
                type="number"
                value={daily}
                onChange={(e) => setDaily(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} display={'flex'} justifyContent="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Monthly</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Monthly"
                variant="outlined"
                size="small"
                type="number"
                value={monthly}
                onChange={(e) => setMonthly(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} display={'flex'} justifyContent="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Yearly</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Yearly"
                variant="outlined"
                size="small"
                type="number"
                value={yearly}
                onChange={(e) => setYearly(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} display={'flex'} justifyContent="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>One Time</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="One Time"
                variant="outlined"
                size="small"
                type="number"
                value={oneTime}
                onChange={(e) => setOneTime(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" justify="flex-start">
            <Grid item xs={4} />
            <Grid item xs={4}>
              <FormControlLabel
                value="end"
                control={<Checkbox color="primary" checked={status} onChange={() => setStatus(!status)} />}
                label="Available for sale"
                labelPlacement="end"
              />
            </Grid>
          </Grid>
        </div>
        <div className={styles.footSec}>
          <DialogActions>
            <Button variant="contained" color="secondary" size="small" onClick={handleClose}>
              CLOSE
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              style={{ backgroundColor: '#00a65a' }}
              disabled={name === '' || categoryId === ''}
              onClick={handleAddItem}
            >
              Create Item
            </Button>
          </DialogActions>
        </div>
      </div>
    </div>
  );
}
