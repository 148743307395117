import React from 'react';
import styles from './styles.module.css';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';

function ItemSummaryReport() {
  return (
    <div className={styles.contentWrapper}>
      {/* <Loader isOpen={loadingIndicator} /> */}

      <div className={styles.titleSec}>
        <span className={styles.title}>
          {' '}
          Reports<span className={styles.menuTitle}>Generation</span>
        </span>
      </div>
    </div>
  );
}

export default withConsoleBase(ItemSummaryReport);
