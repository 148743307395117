import React, { useState, useEffect, useCallback, useContext } from 'react';
import styles from './styles.module.css';
import CustomerService from '../../../services/CustomerService';
import SalesService from '../../../services/SaleService';
import Loader from '../../utils/Loading';
import { useParams } from 'react-router-dom';
import DataTable from '../../utils/DataTable';
import Info from '../../utils/Alert/Info';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import { Grid, Button } from '@material-ui/core';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import SyncIcon from '@mui/icons-material/Sync';
import { Paper, Typography } from '@mui/material';
import CustomerSaleDialog from '../../popups/CustomerSaleDialog';
import { AlertMessagesContext } from 'react-alert-messages';
import Grayout from '../../utils/GrayOut/Grayout';
import DateTimeHelper from '../../../helpers/DateTimeHelpers';
import getSaleTotal from '../../utils/getSaleTotal';
import shortDateDiff from 'short-date-diff';
import { ProductContext } from '../../../Context/ProductContext';

function CustomerDetails() {
  const { postAlertMessage } = useContext(AlertMessagesContext);
  const { items } = useContext(ProductContext);

  const [loadingIndicator, setLoadingIndicator] = useState(null);
  const [activeSales, setActiveSales] = useState([]);
  const [completedSales, setCompletedSales] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [fromDate, setFromDate] = useState(
    DateTimeHelper.getMinTimeOfDate(new Date(new Date().setDate(new Date().getDate() - 5)))
  );
  const [toDate, setToDate] = useState(DateTimeHelper.getMaxTimeOfDate(new Date()));
  const [isShowGenerateButton, setIsShowGenerateButton] = useState(false);
  const [prevFilterData, setPrevFilterData] = useState({
    fromDate,
    toDate,
  });
  const [selectedSale, setSelectedSale] = useState(null);

  const { customerId } = useParams();

  const activeSalesHeaderData = [
    {
      label: 'ID',
      id: 'code',
      type: 'text',
    },
    {
      label: 'Customer',
      id: 'customer',
      type: 'text',
    },
    {
      label: 'Items',
      id: 'item',
      type: 'callback',
      viewRender: (sale) => {
        return sale.items.length;
      },
    },
    {
      label: 'Date',
      id: 'startDate',
      type: 'callback',
      viewRender: (sale) => {
        return (
          <span>
            {DateTimeHelper.convertDateToDMY(new Date(sale.startDate))}
            <br />
            {DateTimeHelper.getTime12HMormat(new Date(sale.startDate))}
          </span>
        );
      },
    },
    {
      label: 'Return',
      id: 'returnDate',
      type: 'callback',
      viewRender: (sale) => {
        if (!sale.returnDate) return '-';
        return (
          <span>
            {DateTimeHelper.convertDateToDMY(new Date(sale.returnDate))}
            <br />
            {DateTimeHelper.getTime12HMormat(new Date(sale.returnDate))}
          </span>
        );
      },
    },
    {
      label: 'Advance',
      id: 'advanceAmount',
      type: 'text',
    },
    {
      label: 'Paid',
      id: 'paidAmount',
      type: 'text',
    },
    {
      label: 'Total',
      id: 'totalAmount',
      type: 'callback',
      viewRender: (data) => getSaleTotal(data, items),
    },
    {
      label: 'Remaining',
      id: 'remainingAmoutn',
      type: 'callback',
      viewRender: (data) => getSaleRemainingAmount(data),
    },
    {
      label: 'Days',
      id: 'day',
      type: 'callback',
      viewRender: (data) => {
        if (data.returnDate) return '-';
        return shortDateDiff(new Date(data.startDate), new Date());
      },
    },
  ];

  const completedSaleHeaderData = [
    {
      label: 'ID',
      id: 'code',
      type: 'text',
    },
    {
      label: 'Customer',
      id: 'customer',
      type: 'text',
    },
    {
      label: 'Items',
      id: 'item',
      type: 'callback',
      viewRender: (sale) => {
        return sale.items.length;
      },
    },
    {
      label: 'Date',
      id: 'startDate',
      type: 'callback',
      viewRender: (sale) => {
        return (
          <span>
            {DateTimeHelper.convertDateToDMY(new Date(sale.startDate))}
            <br />
            {DateTimeHelper.getTime12HMormat(new Date(sale.startDate))}
          </span>
        );
      },
    },
    {
      label: 'Return',
      id: 'Return',
      type: 'callback',
      viewRender: (sale) => {
        return (
          <span>
            {DateTimeHelper.convertDateToDMY(new Date(sale.returnDate))}
            <br />
            {DateTimeHelper.getTime12HMormat(new Date(sale.returnDate))}
          </span>
        );
      },
    },
    {
      label: 'Bill Date',
      id: 'billDate',
      type: 'callback',
      viewRender: (sale) => (
        <span>
          {DateTimeHelper.convertDateToDMY(new Date(sale.billDate))}
          <br />
          {DateTimeHelper.getTime12HMormat(new Date(sale.billDate))}
        </span>
      ),
    },
    {
      label: 'Advance',
      id: 'advanceAmount',
      type: 'text',
    },
    {
      label: 'Paid',
      id: 'paidAmount',
      type: 'text',
    },
    {
      label: 'Tax',
      id: 'totalTax',
      type: 'text',
    },
    {
      label: 'Total',
      id: 'totalAmount',
      type: 'text',
    },
    {
      label: 'Days',
      id: 'day',
      type: 'callback',
      viewRender: (data) => shortDateDiff(new Date(data.startDate), new Date(data.returnDate)),
    },
  ];

  const getSaleRemainingAmount = (sale) => {
    const amount = getSaleTotal(sale, items);
    return (
      <Typography variant="body2" style={{ color: '#5555f5' }} gutterBottom>
        {amount - sale?.advanceAmount}
      </Typography>
    );
  };

  const getCustomerActiveSales = async ({ _fromDate = fromDate, _toDate = toDate } = {}) => {
    setLoadingIndicator(true);
    try {
      const timezoneFreeStartTime = new Date(_fromDate.getTime() - _fromDate.getTimezoneOffset() * 60000);
      const timezoneFreeEndTime = new Date(_toDate.getTime() - _toDate.getTimezoneOffset() * 60000);

      const params = {
        start_time: timezoneFreeStartTime,
        end_time: timezoneFreeEndTime,
        customerId,
        active: true,
      };

      const res = await SalesService.getSales(params);
      setActiveSales(res);
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  };

  const getCustomerCompletedSales = async ({ _fromDate = fromDate, _toDate = toDate } = {}) => {
    setLoadingIndicator(true);
    try {
      const timezoneFreeStartTime = new Date(_fromDate.getTime() - _fromDate.getTimezoneOffset() * 60000);
      const timezoneFreeEndTime = new Date(_toDate.getTime() - _toDate.getTimezoneOffset() * 60000);

      const params = {
        start_time: timezoneFreeStartTime,
        end_time: timezoneFreeEndTime,
        customerId,
      };

      const res = await SalesService.getSales(params);
      setCompletedSales(res);
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  };

  const getCustomerDetails = async () => {
    try {
      const res = await CustomerService.getCustomer(customerId);
      setCustomer(res);
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
  };

  const viewSelectedSale = async (row) => {
    setSelectedSale(row);
  };

  const generateCustomerDetails = () => {
    getCustomerCompletedSales();
    getCustomerActiveSales();
    setIsShowGenerateButton(false);
    setPrevFilterData({
      fromDate,
      toDate,
    });
  };

  const isAnyChangeOnCustomerDetailsFilters = useCallback(() => {
    return fromDate !== prevFilterData.fromDate || toDate !== prevFilterData.toDate;
  }, [fromDate, toDate, prevFilterData.fromDate, prevFilterData.toDate]);

  useEffect(() => {
    if (isShowGenerateButton === null) return;
    setIsShowGenerateButton(isAnyChangeOnCustomerDetailsFilters());
  }, [isAnyChangeOnCustomerDetailsFilters, isShowGenerateButton]);

  useEffect(() => {
    getCustomerDetails();
    generateCustomerDetails();
    //eslint-disable-next-line
  }, [customerId]);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          Transaction<span className={styles.menuTitle}>Details & History</span>
        </h2>
      </div>
      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}>
            <h2 className={styles.subTitle}>Transactions Details</h2>
          </div>
        </div>
        <div className={styles.actionButtons}>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>From</label>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableFuture
                  variant="outlined"
                  format="dd/MM/yyyy"
                  id="date-picker-from"
                  max={new Date()}
                  className={styles.dateBox}
                  value={fromDate}
                  onChange={(date) => {
                    setFromDate(date);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>To</label>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableFuture
                  variant="outlined"
                  format="dd/MM/yyyy"
                  id="date-picker-to"
                  className={styles.dateBox}
                  max={new Date()}
                  value={toDate}
                  onChange={(date) => {
                    setToDate(DateTimeHelper.getMaxTimeOfDate(date));
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Generate Report</label>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                className={styles.actionBtn}
                style={{ backgroundColor: '#00a65a' }}
                onClick={generateCustomerDetails}
                disabled={isShowGenerateButton === false}
              >
                <SyncIcon className={styles.actionBtnIcon} />
                Generate
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.changeable} style={{ paddingBottom: '15px' }}>
        <Grid container alignItems="center" style={{ paddingLeft: '10px', paddingTop: '5px' }} spacing={2}>
          <Grid item xs={2} sm={2} className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Name</h3>
          </Grid>
          <Grid item xs={4} sm={4}>
            <h3 className={styles.inputLabel}>{customer?.name || '-'}</h3>
          </Grid>
          <Grid item xs={2} sm={2} className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Balance</h3>
          </Grid>
          <Grid item xs={4} sm={4}>
            <h3 className={styles.inputLabel}>{customer?.balance || 0}</h3>
          </Grid>
        </Grid>
        <Grid container alignItems="center" style={{ paddingLeft: '10px', paddingTop: '5px' }} spacing={2}>
          <Grid item xs={2} sm={2} className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Mobile</h3>
          </Grid>
          <Grid item xs={4} sm={4}>
            <h3 className={styles.inputLabel}>{customer?.mobile || '-'}</h3>
          </Grid>
          <Grid item xs={2} sm={2} className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Credit Limit</h3>
          </Grid>
          <Grid item xs={4} sm={4}>
            <h3 className={styles.inputLabel}>{customer?.creditLimit || 0}</h3>
          </Grid>
        </Grid>
        <Grid container alignItems="center" style={{ paddingLeft: '10px', paddingTop: '5px' }} spacing={2}>
          <Grid item xs={2} sm={2} className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Location</h3>
          </Grid>
          <Grid item xs={4} sm={4}>
            <h3 className={styles.inputLabel}>{customer?.location || '-'}</h3>
          </Grid>
        </Grid>
      </div>

      {/* ACTIVE SALES SECTION */}
      <Grid className={styles.changeable}>
        <div className={styles.headTitle} style={{ paddingBottom: 20 }}>
          <span className={styles.subTitle}>Active Sales</span>
        </div>
        {activeSales && activeSales.length ? (
          <>
            <Paper className={styles.tableWrapper}>
              <div className={styles.tableContainer}>
                <Grayout open={isShowGenerateButton} />
                <DataTable columns={activeSalesHeaderData} rows={activeSales} rowClickListener={viewSelectedSale} />
              </div>
            </Paper>
          </>
        ) : (
          !loadingIndicator && (
            <Info
              title={'No transactions to list'}
              content={
                'You have no transactions to list with current filter configuration. Please clear the filters or make some sale'
              }
            />
          )
        )}
      </Grid>

      {/*COMPLETED SALES SECTION */}
      <Grid className={styles.changeable}>
        <div className={styles.headTitle} style={{ paddingBottom: 20 }}>
          <span className={styles.subTitle}>Completed Sales</span>
        </div>
        {completedSales && completedSales.length ? (
          <>
            <Paper className={styles.tableWrapper}>
              <div className={styles.tableContainer}>
                <Grayout open={isShowGenerateButton} />
                <DataTable
                  columns={completedSaleHeaderData}
                  rows={completedSales}
                  rowClickListener={viewSelectedSale}
                />
              </div>
            </Paper>
          </>
        ) : (
          !loadingIndicator && (
            <Info
              title={'No transactions to list'}
              content={
                'You have no transactions to list with current filter configuration. Please clear the filters or make some sale'
              }
            />
          )
        )}
      </Grid>

      {selectedSale && (
        <CustomerSaleDialog
          onClose={() => {
            setSelectedSale(null);
          }}
          rowData={selectedSale}
        />
      )}
    </div>
  );
}

export default withConsoleBase(CustomerDetails);
