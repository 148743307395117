import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';
import DataTable from '../utils/DataTable';
import Info from '../utils/Alert/Info';
import React from 'react';

export default function ListDialog(props) {
  const { toggleItemsTab, rowData, tableHeader, title, emptyDataContent } = props;

  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>{title}</h2>
          <div>
            <CloseIcon
              onClick={() => {
                toggleItemsTab('');
              }}
            />
          </div>
        </div>
        <div className={styles.tableContent}>
          {rowData.length && rowData ? (
            <DataTable columns={tableHeader} rows={rowData} />
          ) : (
            <Info title={title} content={emptyDataContent} />
          )}
        </div>
      </div>
    </div>
  );
}
