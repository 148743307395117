const getSaleTotal = (sale, itemList) => {
  const { startDate, items } = sale;
  const now = new Date();

  const getItemTotalRate = (item, saleItem, startDate) => {
    const { hourly, daily, monthly, yearly, oneTime } = item.rent;
    const diffMs = now - new Date(startDate);

    const totalMinutes = diffMs / (1000 * 60);
    const totalHours = diffMs / (1000 * 60 * 60);
    const totalDays = diffMs / (1000 * 60 * 60 * 24);

    const hours = Math.floor(totalHours) + (totalMinutes % 60 > 15 ? 1 : 0);
    const days = Math.floor(totalDays) + (totalHours % 24 > 1 ? 1 : 0);
    const months = Math.ceil(days / 28);
    const years = Math.ceil(days / 365);

    let totalValue = 0;

    const rateDuration = saleItem.rateDuration;
    const rate = saleItem.rate;

    if (rateDuration) {
      const durationValues = {
        hourly: hours,
        daily: days,
        monthly: months,
        yearly: years,
        oneTime: 1,
      };

      totalValue = rate * (durationValues[rateDuration] || 1);
    } else {
      if (hourly) totalValue += hourly * hours;
      if (daily) totalValue += daily * days;
      if (monthly) totalValue += monthly * months;
      if (yearly) totalValue += yearly * years;
      if (oneTime) totalValue += oneTime;
    }

    return totalValue;
  };

  const getTaxAmount = (item, itemTotalRate) => {
    const tax = item.tax;
    if (!tax || tax === 0) return 0;
    return (tax * itemTotalRate) / 100;
  };

  let saleTotal = 0;

  items.forEach((saleItem) => {
    const { itemId, quantity } = saleItem;
    const item = itemList.find((i) => i.id === itemId);

    if (item) {
      const itemTotalRate = getItemTotalRate(item, saleItem, startDate) * quantity;
      const taxAmount = getTaxAmount(item, itemTotalRate);
      saleTotal += itemTotalRate + taxAmount;
    }
  });

  return saleTotal;
};

export default getSaleTotal;
