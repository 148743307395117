import React, { useContext, useEffect, useState } from 'react';
import { Button, MenuItem, TextField } from '@material-ui/core';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import CreateIcon from '@material-ui/icons/Create';
import { AlertMessagesContext } from 'react-alert-messages';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable';
import EditItemDialog from '../../popups/EditItemDialog';
import AddItemDialog from '../../popups/AddItemDialog';
import ItemService from '../../../services/ItemService';
import { saveAs } from 'file-saver';
import Loader from '../../utils/Loading';
import Info from '../../utils/Alert/Info';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import CsvHelper from '../../../helpers/CsvHelper';
import { Paper } from '@mui/material';
import { STATUS } from '../../../const';
import { ProductContext } from '../../../Context/ProductContext';
import SearchIcon from '@material-ui/icons/Search';

function ItemsList() {
  const { postAlertMessage } = useContext(AlertMessagesContext);
  const { items, isLoading: isLoadingProductContext, fetchItems } = useContext(ProductContext);

  const [filterItems, setFilterItems] = useState(items || []);
  const [categories, setCategories] = useState([]);
  const [filterCategory, setFilterCategory] = useState('all');
  const [searchText, setSearchText] = useState('');
  const [selectedItemForEdit, setSelectedItemForEdit] = useState(null);
  const [isShowAddItemDialog, setIsShowAddItemDialog] = useState(false);

  const CSV_COLUMNS = {
    name: 'Name',
    is_available: {
      title: 'Available',
      getValue: (item) => (item.is_available ? 'YES' : 'NO'),
    },
    category_name: {
      title: 'Category',
      getValue: (item) => categories.find((category) => category.id === item.categoryId)?.name,
    },
    stock: 'Stock',
    stockInMaintenance: 'Maintenance',
    hourly: 'Hourly',
    daily: 'Daily',
    monthly: 'Monthly',
    yearly: 'Yearly',
    one_time: 'One Time',
  };

  const dismissEditDialog = () => {
    setSelectedItemForEdit(null);
  };

  const dismissCreateDialog = () => {
    setIsShowAddItemDialog(false);
  };

  const showCreateDialog = () => {
    setIsShowAddItemDialog(true);
  };

  const handleGetExportData = async () => {
    await handleExportCsv(filterItems);
  };

  const handleExportCsv = async (exportItems) => {
    const csv = CsvHelper.getString(exportItems, CSV_COLUMNS);
    const blob = new Blob([csv], {
      type: 'text/csv',
    });
    saveAs(blob, `items-${new Date().toISOString()}.csv`);

    postAlertMessage({
      text: 'Exported to excel successfully',
      type: 'success',
    });
  };

  const handleCategorySearch = (_filterCategory) => {
    setFilterCategory(_filterCategory);
    if (_filterCategory === 'All') {
      setFilterItems(items);
      return;
    }
    setFilterItems(items.filter((item) => item.categoryId === _filterCategory));
  };

  const handleSearch = (value) => {
    if (!value) {
      setFilterItems(items);
    }
    setSearchText(value);
    const filterResponse = filterItems
      ? items.filter(
          (filterItem) =>
            filterItem.name.toLowerCase().includes(value.toLowerCase()) || filterItem.code.toString().includes(value)
        )
      : [];
    setFilterItems(filterResponse);
  };

  const getItemRate = (item) => {
    if (!item) return;
    const { hourly, daily, monthly, yearly, oneTime } = item?.rent;

    return [
      { value: hourly, label: '/hour', key: 'hourly' },
      { value: daily, label: '/day', key: 'daily' },
      { value: monthly, label: '/month', key: 'monthly' },
      { value: yearly, label: '/year', key: 'yearly' },
      { value: oneTime, label: '/one time', key: 'oneTime' },
    ].filter((rate) => rate.value);
  };

  const headerData = [
    {
      label: 'Name',
      id: 'name',
      type: 'text',
      main: 'true',
    },
    {
      label: 'Image',
      id: 'image',
      type: 'image',
    },
    {
      label: 'Stock',
      id: 'stock',
      type: 'text',
    },
    {
      label: 'Available Stock',
      id: 'available_stock',
      type: 'callback',
      viewRender: (item) => {
        return item.stock - item.stockInMaintenance;
      },
    },
    {
      label: 'Tax',
      id: 'tax',
      type: 'text',
    },
    {
      label: 'Available',
      id: 'status',
      type: 'callback',
      viewRender: (item) => {
        return item.status === STATUS.ACTIVE ? 'Yes' : 'No';
      },
    },
    {
      label: 'Maintenance',
      id: 'stockInMaintenance',
      type: 'text',
    },
    {
      label: 'Rate',
      id: 'rate',
      type: 'callback',
      viewRender: (item) => {
        return (getItemRate(item) || []).map((rate) => `${rate.value}${rate.label}`).join(' + ') ?? '-';
      },
    },
    {
      label: 'Update',
      id: 'updateItems',
      type: 'button',
      title: 'Edit Item',
      clickHandler: setSelectedItemForEdit,
    },
  ];

  const getRowStyle = (row) => {
    if (row.stock - row.stockInMaintenance < 0) {
      return { backgroundColor: 'rgba(245, 39, 39, 0.2)' };
    } else if (row.stock - row.stockInMaintenance < 5) {
      return { backgroundColor: 'rgba(245, 169, 39, 0.2)' };
    }
    return;
  };

  useEffect(() => {
    setFilterItems(items);
  }, [items]);

  useEffect(() => {
    const getCategories = async () => {
      const res = await ItemService.getItemCategories();
      setCategories(res);
    };

    getCategories();
  }, []);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={isLoadingProductContext} />
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          {' '}
          Item<span className={styles.menuTitle}>list</span>
        </h2>
      </div>
      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}>
            <h2 className={styles.subTitle}>Products</h2>
          </div>
          <div className={styles.filerInputSec}>
            <TextField
              select
              variant="outlined"
              size="small"
              defaultValue="all"
              selected={filterCategory}
              onChange={(e) => {
                handleCategorySearch(e.target.value);
              }}
              className={styles.selectBox}
            >
              <MenuItem value="all">All</MenuItem>
              {categories.map((category) => (
                <MenuItem value={category.id}>{category.name}</MenuItem>
              ))}
            </TextField>
            <div className={styles.searchSec}>
              <input
                type="text"
                value={searchText}
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
                className={styles.searchInput}
                placeholder="search items"
              />
              <SearchIcon className={styles.searchIcon} />
            </div>
          </div>
        </div>
        <div className={styles.actionButtons}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={styles.actionBtn}
            onClick={showCreateDialog}
          >
            <CreateIcon className={styles.actionBtnIcon} />
            NEW ITEM
          </Button>

          <Button
            variant="contained"
            color="primary"
            size="small"
            className={styles.actionBtn}
            style={{ backgroundColor: '#d81b60' }}
            onClick={handleGetExportData}
          >
            <ImportExportIcon className={styles.actionBtnIcon} />
            EXPORT TO CSV
          </Button>
        </div>
      </div>

      {filterItems &&
        (filterItems.length ? (
          <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '4px' }}>
            <DataTable columns={headerData} rows={filterItems} rowStyle={getRowStyle} />
          </Paper>
        ) : (
          !isLoadingProductContext &&
          !filterItems.length && (
            <>
              <Info
                title={'You have no item to current filter'}
                content={
                  'there are no results that match your current filter configration. Please try a different filter combination to view the desired data. '
                }
              />
            </>
          )
        ))}

      {selectedItemForEdit && (
        <EditItemDialog
          handleClose={dismissEditDialog}
          item={selectedItemForEdit}
          categories={categories}
          onSuccess={fetchItems}
        />
      )}

      {isShowAddItemDialog && (
        <AddItemDialog
          handleClose={dismissCreateDialog}
          categories={categories}
          onSuccess={fetchItems}
        />
      )}
    </div>
  );
}

export default withConsoleBase(ItemsList);
