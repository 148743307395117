import React from 'react';
import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';
import { Alert, AlertTitle } from '@material-ui/lab';

function WarningDialog({ closeHandler, heading, message }) {
  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>{heading}</h2>
          <CloseIcon
            onClick={() => {
              closeHandler(null);
            }}
          />
        </div>
        <div className={styles.changeable} style={{ margin: '10px' }}>
          <Alert severity="warning" style={{ margin: '10px 0' }}>
            <AlertTitle>
              <strong>{heading}</strong>
            </AlertTitle>
            {message}
          </Alert>
        </div>
      </div>
    </div>
  );
}

export default WarningDialog;
