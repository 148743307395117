import BackendService from './BackendService';

async function getItems(params) {
  return BackendService.get('/items/', { params });
}
async function getItemNames() {
  return BackendService.get('/items/name_list/');
}
async function updateItem(itemId, data = {}) {
  return BackendService.put(`/items/${itemId}`, data);
}
async function addItem(data = {}) {
  return BackendService.post('/items/', data);
}
async function getItemCategories() {
  return BackendService.get('/categories/');
}
async function addItemCategory(data = {}) {
  return BackendService.post('/categories/', data);
}
async function uploadCategoryImage(data = {}, id) {
  return BackendService.post(`/category/${id}/image/`, data);
}
async function uploadItemImage(data = {}, id) {
  return BackendService.post(`/item/${id}/image/`, data);
}
async function editItemCategory(data = {}, id) {
  return BackendService.patch(`/categories/${id}`, data)
}
async function updatePlu() {
  return BackendService.post('/items/update_plu_text/', '');
}
async function updateItems(data) {
  return BackendService.post('/items/batch/', data);
}
async function getItemAdvanced(id) {
  return BackendService.get(`/items/${id}/advanced/`);
}
async function updateItemAdvanced(data = {}, id) {
  return BackendService.put(`/items/${id}/advanced/`, data);
}
async function getHighestPlu() {
  return BackendService.get('/items/highest-plu/');
}
const ItemService = {
  getItems,
  getItemNames,
  updateItem,
  addItem,
  getItemCategories,
  addItemCategory,
  editItemCategory,
  uploadCategoryImage,
  uploadItemImage,
  updatePlu,
  updateItems,
  getItemAdvanced,
  updateItemAdvanced,
  getHighestPlu,
};

export default ItemService;
