import axios from 'axios';
import { BACKEND_URL, DEFAULT_ERROR_MESSAGE, STORAGE_KEYS } from '../const';

const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN);
  if (!refreshToken) {
    return;
  }

  const url = `${BACKEND_URL}/auth/login-by-refresh-token`;
  const response = await request({
    url,
    method: 'POST',
    data: { refreshToken },
  });
  localStorage.setItem(STORAGE_KEYS.ACCESS_TOKEN, response.accessToken);
};

const request = async (option, retry = 0) => {
  if (option.requireAuth && !localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN)) {
    throw new Error('Invalid API call, called API require auth without logging in');
  }

  const selectedOrgId = localStorage.getItem(STORAGE_KEYS.SHOP_ID);
  if (option.requireOrg && !selectedOrgId) {
    throw new Error('Invalid API call, called API require organization without selecting organization');
  }

  option.headers = option.headers || {};
  if (option.requireAuth) {
    const accessToken = localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN);
    if (accessToken) {
      option.headers['Authorization'] = `Bearer ${accessToken}`;
    }
  }

  if (option.requireOrg) {
    option.headers['org-id'] = selectedOrgId;
  }

  try {
    const response = await axios(option);
    return response.data;
  } catch (error) {
    const response = error?.response || {};
    const statusCode = response?.status;

    const body = response.data || {};
    if (
      retry < 1 &&
      (statusCode >= 500 || body.code === 'ACCESS_TOKEN_EXPIRED' || body.code === 'MISSING_AUTH_TOKEN')
    ) {
      if (body.code === 'ACCESS_TOKEN_EXPIRED' || body.code === 'MISSING_AUTH_TOKEN') {
        localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN);
        await refreshAccessToken();
      }
      return request(option, retry + 1);
    }

    if (response.status === 401) {
      localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN);
      localStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN);
      window.location.reload();
      throw error;
    }

    _throwHttpError(error);
  }
};

const _throwHttpError = (error) => {
  const response = error?.response?.data || {};
  const message = response.message || DEFAULT_ERROR_MESSAGE;
  throw new Error(message);
};

const ApiHelper = {
  request,
};

export default ApiHelper;
